import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {title: 'のぞみクリニック | 内科 皮膚科 小児科 糖尿病治療　漢方治療　健康診断　オンライン診療　セカンドオピニオン'},
    component: () => import('../views/Home')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  // 解决路由跳转后，不显示在首行
  scrollBehavior() {
    return {x: 0, y: 0};
  }
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
